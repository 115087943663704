.headerRoot {
  //background-color: #ccc;
  & .headbg {
    // width: 100%;
    // min-height: 150px;
    // background-size: 100% 100% !important;
    // background-position: center !important;
    // background-repeat: no-repeat !important;

    width: 100%;
    // max-height: 150px;
    // background-size: 100% 100% !important;
    // background-position: center !important;
    margin: 0 auto;
    // background-repeat: no-repeat !important;
    max-width: 1920px;
    img{
      width: 100%;
    }

  //   @media (max-width: 1199px) {
  //     min-height: 160px;
  //   }
  //   @media (max-width: 991px) {
  //     min-height: 100px;
  //   }
  //   @media (max-width: 767px) {
  //     min-height: 70px;
  //   }
  //   @media (max-width: 575px) {
  //     min-height: 50px;
  //   }
  }

  & .headnav {
    //max-width: 1920px;
    width: 100%;
    padding: 15px 5%;
    color: #fff;
    text-align: left;
    margin: 0 auto;
    min-height: 80px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
      padding: 15px 2%;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }

    & .lfsp {
      flex-basis: 30%;
      max-width: 30%;

      @media (max-width: 1199px) {
        max-width: 34%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        justify-content: space-between;
        margin: 0 0 15px;
        width: 100%;
      }
    }
    & .rssp {
      flex-basis: 70%;
      max-width: 70%;
      justify-content: flex-end;
      @media (max-width: 1199px) {
        max-width: 66%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        justify-content: space-between;
        width: 100%;
      }
    }

    & span {
      display: flex;
    }
    & .btndp {
      min-width: 150px;
      background: none;
      border: 1px solid #fff;
      border-radius: 3px;
      padding: 10px 10px;
      color: #fff;
      font-size: 14px;
      & i {
        font-size: 18px;
        position: absolute;
        left: 10px;
        top: 12px;
      }
      &:after {
        position: absolute;
        right: 9px;
        top: 20px;
      }
    }
    & .backDp {
      & a {
        padding: 0.3rem 1rem;
        font-size: 14px;
      }
    }
    & .hmbtn,
    & .filterbtn {
      border: 1px solid #fff;
      border-radius: 3px;
      padding: 10px 25px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
      position: relative;
      margin: 0 30px;
      min-width: 120px;

      @media (max-width: 767px) {
        margin: 0;
      }
      & i {
        font-size: 20px;
        margin: -3px 5px 0 0;
        vertical-align: middle;
      }
    }
    & .filterbtn {
      margin-right: 0;
    }
    & .hedsrch {
      max-width: 82%;
      position: relative;
      width: 100%;
      // min-height: 44px;

      @media (max-width: 1199px) {
        max-width: 68%;
      }

      @media (max-width: 991px) {
        max-width: 52%;
      }
      @media (max-width: 767px) {
        max-width: 75%;
      }

      & input {
        width: 100%;
        border: 0;
        margin: 0;
        height: 44px;
        border-radius: 3px;
        padding: 0 60px 0 20px;
        font-size: 14px;
      }
      & button {
        position: absolute;
        background: #1f1f1f;
        border: 0;
        right: 2px;
        border-radius: 3px;
        width: 40px;
        height: 40px;
        top: 2px;
        color: #fff;
        font-size: 25px;

        @media (max-width: 1199px) {
          width: auto;
          padding: 0 8px;
        }
      }
    }
    & .filterbtn {
      position: relative;
      display: flex;
      align-items: center;

      @media (max-width: 575px) {
        min-width: auto;
        padding: 0 10px;
      }

      & span {
        @media (max-width: 575px) {
          display: none;
        }
      }
    }
  }
}
