.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
}

.HeaderNav {
  position: sticky;
  top:0;
  z-index: 99;
}

.scsever {
  position: relative;
  z-index: 99999;
}

.scsever .owl-carousel {
      position: fixed;
      height: 100vh;
      width: 100%;
      z-index: 999999;
      overflow: hidden;
    }
.scsever .owl-carousel .owl-item img {
  width: 100%;
  height: 100vh;
  object-fit: fill;
}

@media (max-width: 767px) {
  .HeaderNav  { 
    position: relative;
  }
}
