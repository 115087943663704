.poweredby {
    font-size: 22px;
}

.footerText {
    font-size: 15px;
    margin: 0 0 0 15px;
    @media (max-width: 575px) {
        margin: 8px 0 0;
    }
}

// .poweredbySpan {
//     margin-top: 1%;
// }

@media (max-width: 767px) {
    .poweredbySpan {
        margin: 0;
    }
    .footerText {
        display: none;
    }
}

@media (max-width: 575px) {
    .poweredbySpan {
        font-size: 12px;

    }
}