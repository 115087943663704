.navRoot {
  & .headnav {
    max-width: 100%;
    width: 100%;
    padding: 5px 2%;
    color: #fff;
    text-align: left;
    margin: 0 auto;
    min-height: 60px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
      padding: 5px 2%;
    }
    @media (max-width: 767px) {
      //flex-direction: column;
    }

    & .lfsp {
      // flex-basis: 30%;
      max-width: 30%;

      @media (max-width: 1199px) {
        max-width: 34%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        justify-content: space-between;
        margin: 0 5px 0;
        //width: 100%;
      }
    }
    & .rssp {
      flex-basis: 70%;
      max-width: 70%;
      justify-content: center;
      @media (max-width: 1199px) {
        max-width: 66%;
      }
      @media (max-width: 991px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      @media (max-width: 767px) {
        // max-width: 100%;
        // justify-content: space-between;
        // width: 100%;
      }
    }

    & span {
      display: flex;
    }

    & .btndp {
      min-width: 150px;
      background: none;
      border: 1px solid #fff;
      border-radius: 3px;
      padding: 10px 10px;
      color: #fff;
      font-size: 14px;
      & i {
        font-size: 18px;
        position: absolute;
        left: 10px;
        top: 12px;
      }
      &:after {
        position: absolute;
        right: 9px;
        top: 20px;
      }
    }
    & .backDp {
      & a {
        padding: 0.3rem 1rem;
        font-size: 14px;
      }
    }
    & .hmbtn {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        min-width: auto !important;
        padding: 12px 10px !important;
      }
      & i {
        @media (max-width: 767px) {
          margin: 0 !important;
        }
      }
      & span {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
    & .hmbtn,
    & .filterbtn {
      border: 1px solid #fff;
      border-radius: 3px;
      padding: 10px 25px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
      position: relative;
      margin: 0 30px;
      min-width: 120px;

      @media (max-width: 767px) {
        margin: 0;
      }
      & i {
        font-size: 20px;
        margin: -3px 5px 0 0;
        vertical-align: middle;
      }
    }
    & .filterbtn {
      margin-right: 0;
    }
    & .hedsrch {
      max-width: 82%;
      position: relative;
      width: 100%;
      // min-height: 44px;

      @media (max-width: 1199px) {
        max-width: 68%;
      }

      @media (max-width: 991px) {
        max-width: 100%;
      }
      @media (max-width: 767px) {
        //max-width: 75%;
        margin-right: 5px;
      }

      & input {
        width: 100%;
        border: 0;
        margin: 0;
        height: 44px;
        border-radius: 3px;
        padding: 0 60px 0 20px;
        font-size: 14px;
        &::placeholder {
          @media (max-width: 575px) {
            font-size: 10px;
          }
        }
        @media (max-width: 575px) {
          padding: 0 45px 0 10px;
        }
      }
      & button {
        position: absolute;
        background: #1f1f1f;
        border: 0;
        right: 2px;
        border-radius: 3px;
        width: 40px;
        height: 40px;
        top: 2px;
        color: #fff;
        font-size: 25px;
        text-align: center;
      }
    }
    & .filterbtn {
      position: relative;
      display: flex;
      align-items: center;
      background: none;

      @media (max-width: 575px) {
        min-width: auto;
        padding: 0 10px;
      }

      & span {
        @media (max-width: 575px) {
          display: none;
        }
      }
    }
  }

  & .filternav {
    //max-width: 1920px;
    width: 100%;
    padding: 15px 2%;
    color: #fff;
    text-align: left;
    min-height: 80px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @media (max-width: 991px) {
      padding: 15px 2%;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }

    & .fliterright {
      // max-width: 68.5%;
      // flex-basis: 68.5%;
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      // margin: 0 0 0 auto;
      align-items: center;
      // justify-content: flex-end;
      width: 100%;
      @media (min-width: 1400px) {
        // max-width: 67.5%;
        // flex-basis: 67.5%;
        max-width: 100%;
        flex-basis: 100%;
      }
      @media (max-width: 1199px) {
        margin: 0 0 0 !important;
        max-width: 100%;
        flex-basis: 100%;
        justify-content: center;
      }
      @media (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    & .filternavitemnull {
      max-width: 30%;
      flex-basis: 25%;
      margin-right: 5%;

      @media (max-width: 1199px) {
        max-width: 25%;
      }
      @media (max-width: 767px) {
        visibility: hidden;
      }
    }

    & .filternavitem1 {
      max-width: 100%;
      flex-basis: 100%;
      margin-right: 2%;
      justify-content: flex-start;

      .filterfields {
        padding: 0;
      }
      input {
        width: 100%;
        height: 44px;
        border: 0;
        border-radius: 3px;
        padding: 0 36px 0 16px;
        position: relative;
        z-index: 99;
        background: transparent;
      }

      @media (max-width: 1199px) {
        max-width: 100%;
      }
      @media (max-width: 991px) {
        max-width: 100%;
      }
      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }
      @media (max-width: 575px) {
        margin: 0 0 20px;
      }
    }

    & .filternavitem2 {
      max-width: 44%;
      flex-basis: 44%;
      margin-right: 2%;
      @media (max-width: 1199px) {
        max-width: 31%;
      }
      @media (max-width: 991px) {
        max-width: 30%;
      }
      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }
      @media (max-width: 575px) {
        margin: 0 0 20px;
      }
    }

    & .filternavitem3 {
      // max-width: 22%;
      // flex-basis: 22%;
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      position: relative;
      margin: auto 0 10px 0;
      justify-content: flex-end;
      padding-right: 206px;
      @media (max-width: 1199px) {
        max-width: 25%;
        padding-right: 40px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        font-size: 12px;
        padding-right: 15px;
      }

      & i {
        color: #fff;
        font-size: 20px;
        // font-weight: lighter;
        padding: 5px;
      }
    }

    & .filterfields {
      width: 100%;
      position: relative;
      border: 0;
      margin: 0;
      height: 44px;
      border-radius: 3px;
      padding: 0 15px;
      font-size: 14px;
      background-color: #fff;
      color: #000;
      font-weight: 500; //Medium font-weight
      display: flex;
      align-items: center;

      @media (max-width: 991px) {
        max-width: 100%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }

      & i {
        color: #000;
        font-size: 20px;
        position: absolute;
        right: 5px;
        padding: 5px;
        @media (max-width: 767px) {
          position: absolute;
          right: 2px;
          padding: 2px;
        }
      }
    }
  }

  & .filternav2 {
    max-width: 1920px;
    width: 100%;
    padding: 15px 2%;
    color: #fff;
    text-align: left;
    min-height: 80px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 991px) {
      padding: 15px 2%;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }

    & .filternavitem4 {
      font-weight: normal;
      font-style: normal;
      font-size: 30px;
      max-width: 29%;
      flex-basis: 29%;
      margin-right: 3%;

      @media (max-width: 1199px) {
        max-width: 29%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }

    & .filternavitem5 {
      max-width: 31%;
      flex-basis: 31%;
      margin-right: 2%;
      color: #cdcdcd;
      margin-right: 2%;
      display: flex;
      align-items: center;

      @media (max-width: 1199px) {
        max-width: 31%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }

      & .filterfields2 {
        display: inline-block;
        padding: 5px;
        border: 1px solid #fff;
        color: #fff;
        width: 85%;
        margin-left: 10px;
      }
      & i {
        color: #fff;
        font-size: 20px;
        position: absolute;
        float: right;
        padding: 5px;
      }
    }

    & .filternavitem6 {
      max-width: 31%;
      flex-basis: 31%;
      margin-right: 2%;
      color: #cdcdcd;
      display: flex;
      align-items: center;

      @media (max-width: 1199px) {
        max-width: 31%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }

      & .filterfields2 {
        display: inline-block;
        padding: 5px;
        border: 1px solid #fff;
        color: #fff;
        width: 75%;
        margin-left: 10px;
      }
      & i {
        color: #fff;
        font-size: 20px;
        position: absolute;
        float: right;
        padding: 5px;
      }
    }
  }
}
