.pdfpreviewroot {
  // height: calc(100vh - 54px);
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(177, 177, 177);
    border-radius: 10px;
  }


  .pdf-preview-container {
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .rpv-core__doc-error {
      background: #d1d1d1;

      .rpv-core__doc-error-text {
        background: none;
        color: #c02424;
        font-size: 20px;
      }
    }

    .rpv-core__inner-pages {
      // height: calc(100vh - 180px) !important;
      display: flex;
      align-items: center;
      // background: #d1d1d1;
      // position: absolute;
      // position: relative !important;
      // z-index: 999;
      overflow: auto;
      :first-child {
        position: initial !important;

        width: auto !important;
        margin: auto;
      }
    }
    .rpv-core__inner-page {
      
      background: #d1d1d1;
      width: auto !important;
      margin: auto;
      left: auto !important;
      
    }

    
    .page-navigate-btns {
      position: absolute;
      top: 50%;
      z-index: 1;

      .rpv-core__minimal-button {
        transform: rotate(-90deg);

        svg {
          height: 14px;
        }
      }

      .rpv-core__minimal-button--disabled {
        cursor: not-allowed;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .prev-page-btn {
      left: 0;
      transform: translate(24px, -50%);
    }

    .next-page-btn {
      right: 0;
      transform: translate(-24px, -50%);
    }

    .viewer-container {
      flex: 1;
      overflow: auto;
    }
  }

  .pdf-button-container {
    align-items: center;
    background-color: #eeeeee;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    padding: 3px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9;

    .btn-foot-mobile {
      display: none;
      padding: 0 10px;

      @media (max-width: 767px) {
        display: flex;
      }

      .navigate-btns {
        background: #0052cc no-repeat center;
        padding: 5px 10px;
        height: 30px;
        border: 0;
        border-radius: 4px;
        color: #fff;
        // margin: 10px;
        font-size: 0.75rem;

        &:hover {
          background: #0065ff no-repeat center;
        }

        &:disabled {
          background: #528bdf no-repeat center;
          cursor: not-allowed;

          svg {
            path {
              stroke: #d3d3d3cc;
            }
          }
        }
      }

      svg {
        width: 7px;
        height: 20px;
      }
    }

    .prev-btn-mobile {
      margin-right: auto;
    }

    .next-btn-mobile {
      margin-left: auto;
    }

    .fllscrn-btn {
      position: absolute;
      left: 0;
      padding: 0 25px 0;
    }

    .zoom-control-buttons {
      padding: 0px 2px;

      .dropdown-toggle {
        background: none;
        border: none;
        font-size: 0.75rem;
        padding-left: 6px;
        padding-right: 6px;

        &:focus {
          box-shadow: none;
        }
      }

      .navigate-btns {
        background: #0052cc no-repeat center;
        padding: 5px 10px;
        height: 30px;
        border: 0;
        border-radius: 4px;
        color: #fff;
        margin: auto 3px;
        display: flex;
        align-items: center;
        font-size: 0.75rem;

        &:hover {
          background: #0065ff no-repeat center;
        }

        &:disabled {
          background: #528bdf no-repeat center;
          cursor: not-allowed;
          color: #d3d3d3cc;

          svg {
            path {
              stroke: #d3d3d3cc;
            }
          }
        }

        svg {
          width: 5px;
          height: 10px;
          margin: auto 6px;
        }
      }

      .rpv-core__tooltip-body {
        @media (max-width: 991px) {
          display: none !important;
        }
      }

      // #528BDF
    }

    .page-count {
      margin: auto 10px;

      span {
        font-size: 0.75rem;
      }
    }

    .page-navigate-desktop {
      position: absolute;
      right: 0;
      padding: 0 25px 0;
      display: flex;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}
