.indexRoot {
  padding: 10px 0 5px;
  //min-height: calc(100vh - 282px);
  min-height: 100vh;

  @media (max-width: 575px) {
    min-height: 100vh;
  }
  .area {
    height: calc(100vh - 310px);
    background: transparent;
    padding: 0 15px;
    .content {
      background: transparent;
      min-height: auto;
    }
  }
  .indexRow {
    display: flex;
    // grid-gap: 25px;
    padding: 0 5%;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    // box-sizing: content-box;

    @media (min-width: 1367px) {
      padding: 0 2%;
    }

    @media (max-width: 1199px) {
      // grid-template-columns: repeat(3, 1fr);
      justify-content: center;
    }
    @media (max-width: 767px) {
      // grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 575px) {
      // grid-template-columns: repeat(1, 1fr);
      padding: 0 15px;
      grid-gap: 10px;
    }
    .incol {
      padding: 20px;
      border-radius: 10px;
      display: flex;
      min-height: 260px;
      align-items: center;
      color: #fff;
      background: #393939;
      text-decoration: none;
      flex-basis: 275px;
      max-width: 275px;
      margin: 5px;

      @media (min-width: 1600px) {
        max-width: 250px !important;
      }

      @media (min-width: 1367px) {
        max-width: 262px;
        flex-basis: 100%;
      }

      @media (max-width: 1199px) {
        flex-basis: 270px;
        max-width: 270px;
      }
      @media (max-width: 991px) {
        min-height: 200px;
        flex-basis: 196px;
        max-width: 196px;
      }

      @media (max-width: 767px) {
        flex-basis: 210px;
        max-width: 210px;
      }
      @media (max-width: 575px) {
        flex-basis: 100%;
        max-width: 100%;
        min-height: 100px;
        padding: 8px;
        border-radius: 6px;
      }

      p {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        padding: 0px 0;
        width: 100%;
        // word-break: break-all;
        line-height: 24px;

        @media (max-width: 991px) {
          padding: 0px 0;
          font-size: 18px;
        }
        @media (max-width: 575px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}