.shareroot{
    max-width: 1920px;
    width: 100%;
    min-height: 100vh;
    color: #000;
    text-align: left;
    position: relative;

    & .box{
        width: 50%;
        max-width: 500px;
        max-height: fit-content;
        min-height: fit-content;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 2rem;
        transform: translate(-50%, -50%);
        margin: 0;
        padding: 10px;
        opacity: 1;
        background-color: #fff;
        border-radius: 10px;
        
        & .crossimg{
            position: absolute;
            right: 20px;
            top: 20px;
        }

        & .itemcontainer{
            display: flex;
            flex-direction: column;
            width: 92%;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 2rem;
            color: #000;
            padding: 8%;
        }

        & .socialcontainer{
            display: flex;
            flex-direction: row;
            width: 92%;
            padding: 8%;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }
}