.headerWithText {
    padding-top: 1.5%;
    padding-bottom: 1%;
}

// .scsever .owl-carousel {
//     position: fixed;
//     height: 100vh;
//     width: 100%;
//     z-index: 999999;
//     overflow: hidden;
//   }
//   .scsever .owl-carousel .owl-item img {
//     width: 100%;
//     height: 100vh;
//     object-fit: fill;
//   }
  