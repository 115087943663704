.abhra {
    width: 90%;
}

.css-yk16xz-control {
    height: 44px !important;
}

.nav_fliterright__3Iouc {
    margin-left: 25.5% !important;
}

.nav_filternavitem3__34E-- {
    margin-right: -28px !important;
}

/* .css-g1d714-ValueContainer {
    height: 43px;
}

.nav_filternavitem2__ODhio {
    max-width: 38% !important;
}*/

.css-26l3qy-menu {
    color: black !important;
}

.css-g1d714-ValueContainer,
.css-1hwfws3 {
    max-height: 14px;
}


.nav_filternavitem1__DoBij {
    margin-left: -10px;
}

.search-suggestion-box {
    width: 100%;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-top: 0;
    border-radius: 0px 0px 5px 5px;
    margin-top: 1px;
    padding: 0;
    overflow-y: auto;
    max-height: 250px;
}

.suggestion-value{
    line-height: 26px;
    padding: 5px 15px;
    font-size: 14px;
}

.suggestion-value:hover{
    cursor: pointer;
    background-color: #F9F4F4;
    color: #000000;
}

@media (max-width: 575px) {
    .abhra {
        width: 100%;
    }

    .css-1wa3eu0-placeholder {
        font-size: 12px;
        line-height: 13px;
    }

    .chips {
        margin-top: 15px;
    }
}

@media (max-width: 991px) {
    .chips {
        margin-top: 10px;
    }
}

.chips {
    cursor: pointer;
    background-color: #cdd5ff;
    color: #1f1f1f;
    border-radius: 20px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
}


/* .css-1wa3eu0-placeholder{
    color:white !important;
} */