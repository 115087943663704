.footerRoot {
  position: sticky;
  bottom: 0;
  & .footerrow {
    max-width: 100%;
    width: 100%;
    padding: 5px 1%;
    color: #fff;
    text-align: left;
    margin: 0 auto;
    min-height: 50px;
    align-items: center;

    @media (max-width: 575px) {
      min-height: auto;
      padding: 5px;
    }
    @media (max-width: 767px) {

    }
    & .footerlogo {
      height: 36px;
      @media (max-width: 575px) {
        height: 20px;
      }
    }
  }
}