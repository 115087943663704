$font-size: 12px;
$line-height: 1.4;
$lines-to-show: 2;

.posterRoot {
  padding: 10px 0;

  @media (max-width: 767px) {
    padding: 10px 0;
  }

  .area {
    // height: calc(100vh - 310px);
    background: transparent;
    padding: 0 15px;

    .content {
      background: transparent;
      min-height: auto;
    }
  }

  .container {
    padding: 0 5%;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 1367px) {
      padding: 0 2%;
    }
  }

  .postsrch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 5px;
    max-width: 1920px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      align-items: flex-start;
    }

    h2 {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      margin: 0;

      @media (max-width: 991px) {
        font-size: 16px;
      }

      @media (max-width: 767px) {
        flex-basis: 100%;
        margin: 0 0 15px;
        text-align: left;
      }
    }

    .poscol {
      flex-basis: 50%;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        flex-basis: 50%;
      }

      @media (max-width: 575px) {
        flex-basis: 100%;
        margin: 0 0 10px;
      }

      p {
        color: #cdcdcd;
        font-size: 16px;
        margin: 0 10px 0 0;
        min-width: 60px;
      }

      .css-2b097c-container {
        width: 100%;
      }
    }

    .poscolsc {
      flex-basis: 27%;

      @media (max-width: 1199px) {
        flex-basis: 30%;
      }

      @media (max-width: 991px) {
        flex-basis: 35%;
      }

      @media (max-width: 767px) {
        flex-basis: 46%;
      }

      @media (max-width: 575px) {
        flex-basis: 100%;
      }

      select {
        width: 100%;
        border-radius: 0;
        border: 1px solid #fff;
        background: transparent;
        color: #fff;
        font-size: 14px;
        padding: 6px 10px;
      }
    }

    .css-1pahdxg-control {
      background-color: transparent;

      &:hover {
        border-color: #fff;
        border-radius: 0;
        box-shadow: none !important;
      }
    }

    .css-yk16xz-control {
      align-items: center;
      background-color: transparent;
      border-color: rgb(185, 194, 197);
      border-radius: 0;
      border-style: solid;
    }

    .css-1uccc91-singleValue {
      color: rgb(128, 128, 128) !important;

    }

    .css-26l3qy-menu {
      text-align: left;
    }

    .css-b8ldur-Input {
      color: #cdcdcd;
    }
  }

  .posterrow {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-gap: 25px;
    // padding: 50px 20px 0;
    display: flex;
    // grid-gap: 25px;
    flex-wrap: wrap;
    // box-sizing: content-box;
    justify-content: center;
    // @media (max-width: 991px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }

    // @media (max-width: 575px) {
    //   grid-template-columns: repeat(1, 1fr);
    // }
    .postercol {
      background: #fff;
      border-radius: 6px;
      position: relative;
      cursor: pointer;
      flex-basis: 378px;
      margin: 5px;

      @media (min-width: 1600px) {
        flex-basis: 297px !important;
      }

      @media (min-width: 1367px) {
        flex-basis: 335px;
      }

      @media (max-width: 1199px) {
        flex-basis: 242px;
        max-width: 242px;
      }

      @media (max-width: 991px) {
        flex-basis: 210px;
        max-width: 210px;
      }

      @media (max-width: 575px) {
        max-width: 100%;
        flex-basis: 100%;
      }

      .lazy-load-image-loaded {
        img {
          @media (max-width: 1199px) {
            height: auto;
            max-height: 100px;
            min-height: 100px;
          }

          @media (max-width: 767px) {
            max-height: 160px;
            min-height: 160px;
          }
        }
      }

      img {
        width: 100%;
        height: 167px;
        object-fit: contain;
        border-radius: 6px;
        margin: 0 0 15px;

        @media (max-width: 1199px) {
          height: auto;
          max-height: 167px;
        }
      }

      .postconholder {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 1px 0px 16px rgba(0, 0, 0, 0.5);
        position: relative;

        p {
          display: block;
          /* Fallback for non-webkit */
          display: -webkit-box;
          height: $font-size * $line-height * $lines-to-show;
          /* Fallback for non-webkit */
          font-size: $font-size;
          line-height: $line-height;
          -webkit-line-clamp: $lines-to-show;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          color: #1f1f1f;
          word-break: break-all;

          @media (max-width: 575px) {
            height: auto;
            display: block;
            -webkit-line-clamp: none;
            overflow: visible;
          }
        }

        // p {
        //   font-size: 12px;
        //   line-height: 20px;
        //   color: #1f1f1f;
        //   text-align: left;
        // }
        .tndtag {
          position: absolute;
          min-height: 44px;
          top: -30px;
          left: -10px;
          align-items: center;
          display: flex;
          color: #fff;
          font-size: 14px;
          background-size: cover !important;
          padding: 2px 24px 10px 24px;

          background: url(./tagtrnd.svg) no-repeat;

          img {
            height: 18px;
            width: auto;
            margin: 0 5px 0 0;
            border-radius: 0;
          }
        }
      }

      .postaut {
        display: flex;
        margin: 20px 0 0;
        align-items: center;
        border-bottom: 1px solid #d9d9d9;
        padding: 0 0 12px;

        img {
          width: 25px;
          height: 25px;
          margin: 0 10px 0 0;
          border-radius: 30px;
          object-fit: cover;
        }

        span {
          color: #1f1f1f;
          font-size: 12px;
          font-weight: 600;
          text-align: left;
          line-height: 15px;
          white-space: nowrap;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 1199px) {
            max-width: 180px;
          }

          @media (max-width: 768px) {
            max-width: 160px;
          }
        }
      }

      .pbtngrp {
        display: flex;
        justify-content: space-between;
        padding: 14px 0 0;
        min-height: 38px;

        &>div {
          display: flex;
        }

        .shareCustomIcon {
          img {
            height: 16px !important;
          }
        }

        .ptcl {
          display: flex;
          align-items: center;
          margin: 0 20px 0 0;

          @media (max-width: 991px) {
            margin: 0 12px 0 0;
          }

          span {
            color: #000;
            font-size: 12px;
            font-weight: 500;
          }

          i {
            color: #a8a8a8;
            font-size: 16px;
            margin: 0 5px 0 0;
          }

          img {
            height: 12px;
            margin: 0 5px 0 0;
          }

          a {
            img {
              height: 16px;
            }
          }
        }
      }
    }
  }
}

.popfooter {
  background: #343434;
  // max-width: 1920px;
  width: 100%;
  padding: 15px 1%;
  color: #fff;
  text-align: left;
  margin: 0 auto;
  max-height: 70px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    max-height: none;
    padding: 5px 0;
  }

  .popflogo {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      justify-content: center;
    }

    @media (max-width: 767px) {
      // flex-direction: column;
      padding: 0 10px;
      width: 100%;
    }

    img {
      height: 30px;

      @media (max-width: 575px) {
        height: 25px;
        //margin: 8px 0 0;
      }
    }
  }

  .popfrating {
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;

    @media (max-width: 991px) {
      position: relative;
      padding: 0;
      border-top: 1px solid #d1d1d1;
      border-bottom: 1px solid #d1d1d1;
      margin: 5px 0;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
    }

    span {
      img {
        height: 20px;
        margin: 0 5px;
      }
    }

    p {
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      margin: 5px 0 0;
    }
  }

  .popfmenu {
    button {
      background: none;
      border: 0;
      padding: 0 10px;

      @media (max-width: 1199px) {}

      @media (max-width: 575px) {
        margin: 0 3px;
      }

      img {
        height: 22px;

        @media (max-width: 575px) {
          height: 18px;
        }
      }

      p {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        margin: 5px 0 0;
        color: #fff;
        display: none;

        @media (max-width: 575px) {
          font-size: 10px;
        }
      }
    }
  }
}

// .ovmodal {
//   position: absolute;
//   width: 100%;
//   min-height: 100%;
//   background: rgba(0, 0, 0, 0.9);
//   top: 0;
//   padding: 30px;
//   .container {
//     max-width: none;
//     padding: 0;
//   }
//   .ov-hed {
//     display: flex;
//     justify-content: space-between;
//     border-bottom: 1px solid #535353;
//     padding: 0 0 16px;
//     h2 {
//       color: #fff;
//       margin: 0;
//       font-weight: 600;
//     }
//     button {
//       padding: 0;
//       border: 0;
//       background: none;
//       color: #f00;
//       img {
//         height: 15px;
//         margin: 0 3px 0 0;
//       }
//     }
//   }
//   .ov-body {
//     padding: 30px 0;
//     p {
//       color: #fff;
//       text-align: left;
//       font-size: 16px;
//       display: flex;
//       margin: 0 0 30px;
//       @media (max-width: 575px) {
//         flex-direction: column;
//       }
//       span {
//         color: #a8a8a8;
//         flex-basis: 30%;
//         max-width: 30%;
//         flex-shrink: 0;
//       }
//     }

//     .postaut {
//       display: flex;

//       @media (max-width: 991px) {
//         margin-top: 50px;
//       }
//       img {
//         width: 80px;
//         height: 80px;
//         border-radius: 50px;
//         margin: 0 15px 0 0;
//         object-position: top;
//       }
//       span {
//         text-align: left;
//         h2 {
//           font-size: 20px;
//           text-align: left;
//           color: #fff;
//           font-weight: 600;
//         }
//         p {
//           font-size: 14px;
//         }
//         button {
//           background: #3d5bf4;
//           border: 0;
//           font-size: 14px;
//           color: #fff;
//           padding: 8px 15px;
//           border-radius: 5px;
//           i {
//             font-size: 18px;
//             margin: 0 5px 0 0;
//           }
//         }
//       }
//     }
//   }
// }

//
.ovmodal {
  width: 100%;
  padding: 0 30px 30px;
  position: fixed;
  z-index: 100002;
  max-height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  overflow-y: auto;
  display: none;
  height: 100%;

  @media (max-width: 767px) {
    padding: 15px;
  }

  .container {
    max-width: none;
    padding: 0;
  }

  .ov-hed {
    border-bottom: 1px solid #535353;
    padding: 16px 0 16px;
    position: relative;
    text-align: left;

    h2 {
      color: #fff;
      margin: 0;
      font-weight: 600;
    }

    .close {
      right: 0;
      top: 26px;
      height: auto;
      color: #f00;
      background: url(ionic-md-close.png) no-repeat !important;
      background-size: 15px !important;
      background-position: left center !important;
      position: absolute !important;
      z-index: 999;
      border: 0;
      padding-left: 20px;
    }
  }

  .ov-body {
    padding: 30px 0;

    @media (max-width: 991px) {
      padding: 30px 0 50px;
    }

    p {
      color: #fff;
      text-align: left;
      font-size: 16px;
      display: flex;
      margin: 0 0 30px;

      @media (max-width: 575px) {
        flex-direction: column;
      }

      span {
        color: #a8a8a8;
        flex-basis: 30%;
        max-width: 30%;
        flex-shrink: 0;

        @media (max-width: 575px) {
          max-width: 100%;
        }
      }
    }

    .left-ovbody {
      p {
        max-width: 85%;

        @media (max-width: 991px) {
          max-width: 100%;
        }
      }
    }

    .postaut {
      display: flex;

      @media (max-width: 991px) {
        margin-top: 50px;
      }

      img {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        margin: 0 15px 0 0;
        object-position: top;
      }

      span {
        text-align: left;

        h2 {
          font-size: 20px;
          text-align: left;
          color: #fff;
          font-weight: 600;

          @media (max-width: 575px) {
            font-size: 18px;
          }
        }

        p {
          font-size: 14px;
        }

        button {
          background: #3d5bf4;
          border: 0;
          font-size: 14px;
          color: #fff;
          padding: 8px 15px;
          border-radius: 5px;

          i {
            font-size: 18px;
            margin: 0 5px 0 0;
            font-style: normal;
          }
        }
      }
    }
  }

  .ovposterrow {
    .pr-3 {
      padding-right: 30px;

      @media (max-width: 991px) {
        padding: 0 15px 50px;
      }
    }

    .pl-3 {
      padding-left: 30px;

      @media (max-width: 991px) {
        padding: 0 15px 0;
      }
    }

    .posterrow {
      flex-wrap: nowrap;
      justify-content: flex-start;

      @media (max-width: 575px) {
        flex-wrap: wrap;
      }
    }

    h2 {
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      margin: 0 0 25px;
      display: flex;
      align-items: center;

      @media (max-width: 1199px) {
        font-size: 16px;
      }

      @media (max-width: 575px) {
        font-size: 12px;
      }

      a {
        font-weight: 400;
        color: #8c8c8c;
        margin: 0 0 0 auto;
        font-size: 18px;

        @media (max-width: 1199px) {
          font-size: 14px;
        }

        @media (max-width: 575px) {
          font-size: 12px;
        }
      }
    }

    .postercol {
      flex-basis: 194px;

      @media (max-width: 575px) {
        flex-basis: 100%;
      }

      .ptcl {
        @media (max-width: 1199px) {
          margin: 0 5px 0 0 !important;
        }
      }

      .pbtngrp .ptcl img {
        width: auto;
      }

      .postconholder p {
        margin-bottom: 0;
      }

      .postaut {
        margin: 8px 0 0;
      }
    }
  }
}

.contactdwnp {
  z-index: 999999 !important;
}

.dwnp-modal {
  z-index: 99999;
}

.dwnp-modal .modal-content {
  border: 0;
  border-radius: 10px;
}

.dwnp-modal .modal-header {
  padding: 0;
  border-bottom: 0;
}

.dwnp-modal .modal-header .close {
  background: transparent;
  border: 0;
  font-size: 30px;
  margin: 0 8px 0 auto;
}

.dwnp-modal .modal-body {
  text-align: center;
  font-family: "Poppins", sans-serif;
  padding: 0 0 40px;
}

.dwnp-modal .modal-body .dp-icon {
  margin: 0 0 10px;
}

.dwnp-modal .modal-body h2 {
  font-size: 22px;
  font-weight: 400;
  margin: 0 0 20px;
}

.dp-from {
  margin: 0 auto;
  max-width: 80%;
}

.dp-from .from-group {
  margin: 0 0 20px;
}

.dp-from .from-group label {
  display: block;
  text-align: left;
  font-weight: 600;
  margin: 0 0 8px;
  font-size: 14px;
}

.dp-from .from-group .form-control {
  font-size: 14px;
  background: #f4f4f4;
  border-color: #c4c4c4;
  padding: 10px 15px;
}

.dp-from button {
  background: #3d5bf4;
  display: block;
  width: 100%;
  border: 0;
  padding: 8px;
  border-radius: 6px;
  color: #fff;
}

.dp-from button i {
  margin: 0 5px 0 0;
}

.dp-from p {
  font-size: 12px;
  color: #848484;
  margin: 8px 0 0;
}

.react-transform-wrapper {
  width: 100% !important;
  min-height: 100vh;
}

.react-transform-component {
  width: 100% !important;

  >div {
    width: 100%;
  }
}

// Rating CSS

.rate {
  float: left;
  // height: 46px;
  padding: 0 10px;

  @media (max-width: 991px) {
    height: auto;
    margin: 6px 0;
  }
}

.rate:not(:checked)>input {
  position: absolute;
  opacity: 0;
}

.rate:not(:checked)>label {
  float: right;
  width: 40px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 38px;
  color: #ccc;
  text-indent: 10px;

  @media (max-width: 991px) {
    font-size: 30px;
    text-indent: 14px;
  }
}

.rate:not(:checked)>label:before {
  content: "★";
}

.rate>input:checked~label {
  color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
  color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
  color: #c59b08;
}

/* Modified from: https://github.com/mukulkant/Star-rating-using-pure-css */

#iframe_container {
  background-color: #ffffff;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: visible;
}

#myiframe {
  overflow: scroll;
  border: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

button {
  display: inline-block;
}

// Testing Modal CSS

#mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #d1d1d1;
  z-index: 10000;
}

#popup {
  position: fixed;
  z-index: 10001;
  display: none;
  top: 0;
  margin: 0 auto;
  width: 100%;
  left: 0;
  background-color: #d1d1d1;
  min-height: 100vh;

  .closemask {
    position: fixed;
    right: 20px;
    top: 5px;
    border: 0;
    background: none;
    color: #000;
    font-size: 33px;
    z-index: 999;
  }
}

.innerdivsec {
  // max-width: 1140px;
  margin: 0 auto;
  padding: 0 0 60px;
  min-height: 100vh;
  width: 100%;

  // @media (max-width: 991px) {
  //   min-height: calc(100vh - 60px);
  // }
}

// Testing Modal CSS end

// Social share dropdown css

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: transparent;
  // min-width: 160px;
  // box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  // padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

ul {
  list-style-type: none;
}

dl,
ol,
ul {
  margin-top: -120px;
  margin-bottom: 1rem;
  padding-left: 21px;
}

// End social share dropdown css

#load-more {
  margin: 1%;
}

.posterSelects .css-yk16xz-control .css-g1d714-ValueContainer .css-1wa3eu0-placeholder {
  color: rgb(128, 128, 128) !important;
}

.timetag.dropdown {
  position: absolute;
  top: -30px;
  right: 3px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.timetag.dropdown button {
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  font-size: 12px;
  color: #fff;
  border-radius: 4px;
  padding: 4px 10px;
}

.timetag.dropdown .dropdown-menu {
  width: 100%;
  transform: none !important;
  top: 27px !important;
  right: 0 !important;
  left: auto !important;
  background: #292929;
  border-radius: 4px;
  padding: 15px 15px 0;
  max-width: 98%;
}

.timetag.dropdown .dropdown-menu li {
  font-size: 14px;
  margin: 0 0 20px;
  color: #c5c5c5;

  @media (max-width: 575px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.timetag.dropdown .dropdown-menu li span {
  display: block;
  color: #fff;
}

// .previewDownloadBtn {
//   	display: none;
// }

// .previewShareBtn {
// 	display: none;
// }

.footerTextPoster {
  font-size: 15px;
  margin: 0 0 0 15px;

  @media (max-width: 767px) {
    margin: 0 0 0 auto;
  }

  @media (max-width: 575px) {
    //margin: 8px 0 0;
    font-size: 12px;
  }
}

.embedAbstract {
  width: 100%;
  height: 100vh;
}

.css-g1d714-ValueContainer,
.css-1hwfws3 {
  max-height: 36px !important;
}

.rate span span {
  margin: 0 1px;
}


.poster-drop {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 6px 6px 0px 0px;
  color: rgb(255, 255, 255);
  width: auto;
  padding: 4px 10px 4px;
  display: flex;
  position: absolute;
  right: 0px;
  top: -30px;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  margin: 0 10px 0 0;

  i {
    margin: 0 0 0 10px;
  }
}

.poster-drop-down {
  background-color: rgb(41, 41, 41);
  color: rgb(255, 255, 255);
  text-align: start;
  width: 94%;
  border-radius: 5px;
  margin: -16px 0px 0px 10px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  font-size: 14px;
}